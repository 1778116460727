<template>
  <div>
    <fieldset class="package-selections-container">
      <div class="package-selections-options fc-flex fc-w-full">
        <label
          v-for="option in selects"
          :key="option.id"
          class="fc-w-full fc-margin-top-2"
          :for="option.uid">
          <input
            :id="option.uid"
            v-model="selectedValue"
            type="radio"
            :value="option.id"
            name="product_selection"
            class="package-selections-input">
          <span class="package-selections-option button-layout button-secondary button-small">{{ option.name }}</span>
        </label>
      </div>
    </fieldset>
    <p v-if="sizeDescription" class="package-selections-option-description">
      {{ sizeDescription }}
    </p>
  </div>
</template>

<script lang="ts">
  import {
    defineComponent, ref, watch, onMounted, PropType, computed,
  } from '@vue/composition-api';
  import { ISelect, SizeSelectorsProps } from './types';

  export default defineComponent({
    name: 'SizeSelectors',
    emits: ['selectedValue'],
    props: {
      selects: {
        // TODO remove SizeSelectorsProps type once all accessories are moved to cms fc-products
        type: Array as PropType<ISelect[] | SizeSelectorsProps[]>,
        required: true,
        default: () => [
          {
            id: '',
            name: '',
            uid: '',
          },
        ],
      },
    },
    setup(props: any, context) {
      const selectedValue = ref(props.selects[0].id);

      watch(selectedValue, (newVal) => {
        context.emit('selectedValue', newVal);
      });

      onMounted(() => {
        context.emit('selectedValue', selectedValue.value);
      });

      const sizeDescription = computed(() => {
        const selectedOption = props?.selects?.options?.find((item: SizeSelectorsProps) => item.key === selectedValue.value);
        return selectedOption ? selectedOption.htc : '';
      });

      return {
        selectedValue,
        showSizeDescription: computed(() => props?.selects?.options[0]?.htc),
        sizeDescription,
      };
    },
  });
</script>

<style lang="scss" scoped>
  @import "../../styles/exports";

.package-selections {
  &-container {
    border: none;
    margin: 0;
    padding: 0;
  }

  &-options {
    flex-wrap: nowrap;

    label {
      margin-right: 0.5rem;

      @media #{$tablet-up} {
        flex: 50%;
      }
    }
  }

  &-input {
    // hide radio button visually
    position: absolute;
    top: auto;
    left: -20px;
  }

  &-option {
    padding: 0.5rem 1rem;

    &-description {
      color: $color-secondary-dark-gray;
      font-size: 14px;
      text-align: center;
    }
  }

  &-input:checked + .package-selections-option {
    background-color: $fc-blue-primary-800;
    color: $color-white;
    border: 1px solid $fc-blue-primary-800;
  }
}
</style>
